<!--地标富农-->
<template>
  <div class="main-div">
    <!--面包屑-->
    <el-row class="breadTop">
      <el-col>
        <top-path-list :path-obj="pathObj"></top-path-list>
      </el-col>
    </el-row>
    <div class="content-div">
      <!--搜索区域-->
      <div class="searchDiv">
        <el-input
          v-model="keyWord"
          placeholder="请输入地标名称搜索"
          prefix-icon="el-icon-search"
          clearable
          @clear="handleEmpty"
          @keyup.enter.native="searchEnter"></el-input>
        <el-button class="moreBtn" @click="searchEnter">搜索</el-button>
      </div>
      <!--一级分类-->
      <el-tabs v-model="activeName" class="firstSort">
        <el-tab-pane label="全部分类" name="allSort">
          <div slot="label" @click="firstSortClick(item)">
            <img src="@/assets/img/allCateBlue.png" alt="" v-if="isAll == true">
            <img src="@/assets/img/allCate.png" alt="" v-if="isAll == false">
            全部分类
          </div>
        </el-tab-pane>
        <el-tab-pane v-for="(item, index) in firstSortList" :key="index" :label="item.typeName" :name="item.typeName">
          <div slot="label" @click="firstSortClick(item)">
            <img :src="item.img" alt="">
            {{item.typeName}}
          </div>
        </el-tab-pane>
      </el-tabs>
      <!--二级分类-->
      <div class="sortDiv">
        <div class="sortList-left">
          <div class="left-title">分类：</div>
        </div>
        <div class="sortList-center">
          <ul class="sortClass">
            <li class="cursorPointer">
              <span :class="sortIsAll == true ? 'sortAll' : 'sortNotAll'" @click="clickTypeTab(item, index)">全部</span>
            </li>
            <li v-for="(item, index) in secondSortList" :key="index" :class="typeActiveIndex === index ? 'active' : 'unActive'" @click="clickTypeTab(item, index)" class="cursorPointer">
              <span class="querySpanClass">{{ item.typeName }}</span>
            </li>
          </ul>
        </div>
      </div>
      <!--三级分类-->
      <div class="subSortDiv" v-if="showThird">
        <div class="sub-sortList-left">
          <div class="sub-left-title">分类：</div>
        </div>
        <div class="sub-sortList-center">
          <ul class="subSortClass">
            <li class="cursorPointer">
              <span :class="subSortIsAll == true ? 'subSortIsAll' : 'subSortNotAll'" @click="clickSubTypeTab(item, index)">全部</span>
            </li>
            <li v-for="(item, index) in thirdSortList" :key="index" :class="subTypeActiveIndex === index ? 'active' : 'unActive'" @click="clickSubTypeTab(item, index)" class="cursorPointer">
              <span class="querySpanClass">{{ item.typeName }}</span>
            </li>
          </ul>
        </div>
      </div>

      <!--新增查询条件，地标标签-->
      <div class="giLabelDiv">
        <div class="label-list-left">
          <div class="label-left-title">地标标签：</div>
        </div>
        <div class="label-list-center">
          <ul class="labelClass">
            <li class="cursorPointer">
              <span :class="labelIsAll == true ? 'labelAll' : 'labelNotAll'" @click="clickLabelTab(item, index)">全部</span>
            </li>
            <li v-for="(item, index) in labelList" :key="index" :class="labelActiveIndex === index ? 'active' : 'unActive'" @click="clickLabelTab(item, index)" class="cursorPointer">
              <span class="querySpanClass">{{ item.label }}</span>
            </li>
          </ul>
        </div>
      </div>

      <!--地区-->
      <div class="areaDiv">
        <div class="areaList-left">
          <div class="left-title">地区：</div>
        </div>
        <div class="areaList-center">
          <ul class="areaClass">
            <li class="cursorPointer">
              <span :class="areaIsAll == true ? 'areaAll' : 'areaNotAll'" @click="clickAreaTab(item, index)">全部</span>
            </li>
            <li v-for="(item, index) in areaList" :key="index" :class="areaActiveIndex === index ? 'active' : 'unActive'" @click="clickAreaTab(item, index)" class="cursorPointer">
              <span class="querySpanClass">{{ item.regionShortName }}</span>
            </li>
          </ul>
        </div>
      </div>
      <!--查询条件及条数-->
      <div class="search-total">
        <!--查询条件-->
        <div class="searchClass">
          <el-tag
            v-for="tag in tags"
            :key="tag.code"
            closable
            @close="handleClose(tag)">
            {{tag.name}}
          </el-tag>
          <el-button v-if="tags.length > 0" @click="emptyClick" class="emptyClass">清空</el-button>
        </div>
        <!--条数-->
        <div class="totalDiv">
          共 <div class="totalNum">{{tableTotal}}</div>个结果
        </div>
      </div>
      <!--产品列表-->
      <div class="listContain" :loading="loading">
        <div class="infoDiv" v-for="(item, index) in dataList" :key="index" @click="goDetail(item)">
          <!--返回数据没有图片时，显示默认图片 TODO 默认图片暂时没有-->
          <img :src="item.img" v-if="item.img" alt="" />
          <!--        <img src="@/assets/img/jujube.png" v-else alt="" />-->
          <div class="textDiv">
            <!--标题-->
            <div class="infoTitle">{{ item.giProductsName }}</div>
            <!--简介-->
            <div class="introduction">{{item.remak}}</div>
            <!--此方法翻页后内容不刷新-->
            <!--<text-ellipsis :text="item.remak" :height="100" style="width: 100%" class="introduction">
              <template slot="more">...</template>
            </text-ellipsis>-->
            <!--地址-->
            <div class="address"><img src="@/assets/img/addressIcon.png" alt="" style="margin-right: 10px">{{item.giProductsProducer}}</div>
          </div>
        </div>
      </div>

      <!--分页-->
      <div class="page">
        <el-pagination
          :key="pageKey"
          :page-sizes="[12, 20, 50, 100]"
          :page-size="pageSize"
          :current-page.sync="page"
          @current-change="pageChange"
          @size-change="handleSizeChange"
          @prev-click="prevClick"
          @next-click="nextClick"
          layout="total, sizes, prev, pager, next, jumper"
          :total="tableTotal">
        </el-pagination>
      </div>
    </div>
    <!--增加取消下方白条-->
    <div style="height: 20px"></div>
  </div>
</template>

<script>
import topPathList from '@/components/topPathList'
import axios from 'axios'
import textEllipsis from '@/components/textellipsis'
export default {
  name: 'index',
  components: { topPathList, textEllipsis },
  data() {
    return {
      pathObj: [
        {
          name: '首页',
          path: '/'
        },
        {
          name: '地标品牌',
          path: ''
        },
        {
          name: '地标产品',
          path: '/richPeasant/brandPavilion'
        },
      ],
      activeName: 'allSort',
      firstSortList: [], //一级分类
      firstSortCode: '', //一级分类的code
      secondSortCode: '', //二级分类的code
      secondSortList: [], //二级分类
      thirdSortList: [],//三级分类
      areaList: [],//地区
      sortIsAll: true,//分类全部显示样式判断
      subSortIsAll: true,//三级分类全部显示样式判断
      areaIsAll: true,//地区全部显示样式判断
      showThird: false, //三级分类是否展示
      page: 1,
      // 总条数
      tableTotal: 0,
      pageSize: 12,
      keyWord: '',
      typeActiveIndex: '',
      subTypeActiveIndex: '',
      areaActiveIndex: '',
      loading: false,
      dataList: [],
      type: '', //类型
      firstType: '', //类型
      secondType: '', //类型
      thirdType: '', //类型
      isSecond: false,//是不是二级分类
      isThird: false,//是不是二级分类
      county: '', //地区id
      tags: [],//查询条件
      searchFirstList: [],//查询条件
      searchSecondList: [],//查询条件
      searchThirdList: [],//查询条件
      projectId: '',//产品id
      cityFromRoute: '',//品牌馆路由带过来的城市
      isAll: true,//控制一级分类的全部分类显示灰色还是蓝色
      labelList: [],//地标标签字典值
      labelActiveIndex: '',
      giSign: '',//地标标签
      labelIsAll: true,
      productParamSize: '',
      productParamCurrent: '',
      productParamCounty: '',
      productParamSearchKey: '',
      productParamGiSign: '',
      productParamType: '',
      productParamIsThird: '',
      productParamIsSecond: '',
      productParamAreaActiveIndex: '',
      productParamLabelActiveIndex: '',
      fromDetail: false,
      firstTypeItem: {},
      productParamFirstTypeItem: {},
      secondTypeItem: {},
      productParamSecondTypeItem: {},
      secondIndex: 0,
      productParamSecondIndex: '',
      thirdTypeItem: {},
      productParamThirdTypeItem: {},
      thirdIndex: 0,
      productParamThirdIndex: '',
      pageKey: 0,
    }
  },
  beforeRouteEnter (to, from, next) {
    let fromPath = from.path
    if (fromPath == "/richPeasant/productInfo") { //从产品信息页返回查询条件
      next((vm) => {
        vm.fromDetail= true
        vm.productParamSize = Number(from.query.productParamSize)
        vm.productParamCurrent = Number(from.query.productParamCurrent)
        vm.productParamCounty = from.query.productParamCounty
        vm.productParamSearchKey = from.query.productParamSearchKey
        vm.productParamGiSign = from.query.productParamGiSign
        vm.productParamIsThird = from.query.productParamIsThird
        vm.productParamIsSecond = from.query.productParamIsSecond
        vm.productParamType = from.query.productParamType
        vm.productParamAreaActiveIndex = from.query.productParamAreaActiveIndex
        vm.productParamLabelActiveIndex = from.query.productParamLabelActiveIndex
        vm.productParamFirstTypeItem = from.query.productParamFirstTypeItem
        vm.productParamSecondTypeItem = from.query.productParamSecondTypeItem
        vm.productParamSecondIndex = from.query.productParamSecondIndex
        vm.productParamThirdTypeItem = from.query.productParamThirdTypeItem
        vm.productParamThirdIndex = from.query.productParamThirdIndex
      })
    }
    next()
  },
  mounted () {
    //如果是详情页面返回
    if (this.fromDetail == true) {
      this.getGiTypeDict()
      this.getArea()
      this.pageSize = this.productParamSize
      this.page = this.productParamCurrent
      this.county = this.productParamCounty
      this.keyWord = this.productParamSearchKey
      this.giSign = this.productParamGiSign
      this.isThird = this.productParamIsThird === 'false' ? false : true; //是不是三级分类
      this.isSecond = this.productParamIsSecond === 'false' ? false : true; //是不是二级分类
      if (this.productParamType != undefined) {
        if (this.isThird != false) { //如果是三级分类
          this.thirdType = this.productParamType
        } else if (this.isSecond != false) { //如果是二级分类
          this.secondType = this.productParamType
        } else { //如果是一级分类
          this.firstType = this.productParamType
        }
      }
      /*地区变蓝*/
      if (this.productParamAreaActiveIndex != '') { //如果点击了地区
        this.areaActiveIndex = Number(this.productParamAreaActiveIndex)
        this.areaIsAll = false //全部按钮不显示蓝色
      } else {
        this.areaIsAll = true //全部按钮显示蓝色
      }
      /*地标标签变蓝*/
      if (this.productParamLabelActiveIndex != '') { //如果点击了地标标签
        this.labelActiveIndex = Number(this.productParamLabelActiveIndex)
        this.labelIsAll = false //全部按钮不显示蓝色
      } else {
        this.labelIsAll = true //全部按钮显示蓝色
      }
      /*一级分类样式*/
      if (this.isThird == false && this.isSecond == false) { //点击了一级分类
        //如果一级分类点击的是全部
        if (JSON.stringify(this.productParamFirstTypeItem) == "{}" || this.productParamFirstTypeItem == undefined) {
          this.activeName = 'allSort' //一级分类的tab切换到全部
        } else { //一级分类点击的是非全部
          this.firstTypeItem = this.productParamFirstTypeItem; //this.firstTypeItem接收对应点击的对象
          this.activeName = this.firstTypeItem.typeName //一级分类的tab切换到对应名称
          //将一级分类的值放入查询条件中
          this.tags = []
          let obj = {}
          obj.name = this.activeName
          obj.code = this.firstType
          this.tags.push(obj)
        }
        this.getSortOther() //调用一级分类查询接口
      }
      /*二级分类样式*/
      if (this.secondType != '') {
        //让一级分类对应的名称变蓝
        //如果一级分类点击的是全部
        if (JSON.stringify(this.productParamFirstTypeItem) == "{}") {
          this.activeName = 'allSort' //一级分类的tab切换到全部
        } else { //一级分类点击的是非全部
          this.firstTypeItem = this.productParamFirstTypeItem; //this.firstTypeItem接收对应点击的对象
          this.activeName = this.firstTypeItem.typeName //一级分类的tab切换到对应名称
        }
        this.getSortOtherTwo() //调用一级分类查询接口
      }
      /*三级分类样式*/
      if (this.thirdType != '') {
        this.thirdTypeItem = this.productParamThirdTypeItem
        this.thirdIndex = Number(this.productParamThirdIndex)
        //让一级分类对应的名称变蓝
        //如果一级分类点击的是全部
        if (JSON.stringify(this.productParamFirstTypeItem) == "{}") {
          this.activeName = 'allSort' //一级分类的tab切换到全部
        } else { //一级分类点击的是非全部
          this.firstTypeItem = this.productParamFirstTypeItem; //this.firstTypeItem接收对应点击的对象
          this.activeName = this.firstTypeItem.typeName //一级分类的tab切换到对应名称
        }
        this.getSortOtherThree() //调用一级分类查询接口
      }
    } else {
      this.getGiTypeDict()
      this.getArea()
      this.getSort()
      this.getSortOther()
      this.cityFromRoute = this.$route.query.city
      if (this.cityFromRoute != "" && this.cityFromRoute != undefined) {//是从品牌馆点击进入的
        this.getDataByRouteCity()
      } else {//直接进入的
        this.getList()
      }
    }

  },
  methods: {
    //查询地标标签字典值
    getGiTypeDict() {
      axios({
        method: "get",
        url: process.env.VUE_APP_HUSSAR_DEFAULT_API + "/dicFront/selectListByTypeName" + "?typeName=gi_label",
      }).then(res => {
        if (res.data.code === 10000 || res.data.code === 200) {
          this.labelList = res.data.data

        } else {
          this.$message.warning("请求失败")
        }
      }).catch(err => {
        this.$message.error('请求失败')
      })
    },

    //地标标签点击
    clickLabelTab(val, index) {
      this.page = 1
      this.pageSize = 12
      if (val == undefined) {
        this.labelActiveIndex = ""
        this.labelIsAll = true
        this.giSign = ""
        this.getList()
      } else {
        this.labelActiveIndex = index
        this.labelIsAll = false //全部按钮不显示蓝色
        this.giSign = val.value
        this.getList()
      }
    },

    //根据路由带过来的城市信息进行查询
    getDataByRouteCity() {
      //调用产品列表查询接口
      this.county = this.cityFromRoute
      this.getList()
      this.areaIsAll = false //全部取消变蓝
      //地区查询条件对应的城市变蓝选中
      if (this.cityFromRoute == "济南市") {
        this.areaActiveIndex = 0
      }
      if (this.cityFromRoute == "青岛市") {
        this.areaActiveIndex = 1
      }
      if (this.cityFromRoute == "淄博市") {
        this.areaActiveIndex = 2
      }
      if (this.cityFromRoute == "枣庄市") {
        this.areaActiveIndex = 3
      }
      if (this.cityFromRoute == "东营市") {
        this.areaActiveIndex = 4
      }
      if (this.cityFromRoute == "烟台市") {
        this.areaActiveIndex = 5
      }
      if (this.cityFromRoute == "潍坊市") {
        this.areaActiveIndex = 6
      }
      if (this.cityFromRoute == "济宁市") {
        this.areaActiveIndex = 7
      }
      if (this.cityFromRoute == "泰安市") {
        this.areaActiveIndex = 8
      }
      if (this.cityFromRoute == "威海市") {
        this.areaActiveIndex = 9
      }
      if (this.cityFromRoute == "日照市") {
        this.areaActiveIndex = 10
      }
      if (this.cityFromRoute == "临沂市") {
        this.areaActiveIndex = 11
      }
      if (this.cityFromRoute == "德州市") {
        this.areaActiveIndex = 12
      }
      if (this.cityFromRoute == "聊城市") {
        this.areaActiveIndex = 13
      }
      if (this.cityFromRoute == "滨州市") {
        this.areaActiveIndex = 14
      }
      if (this.cityFromRoute == "菏泽市") {
        this.areaActiveIndex = 15
      }
    },


    //搜索框搜索
    searchEnter() {
      this.getList()
    },
    //搜索框清空
    handleEmpty() {
      this.getList()
    },
    //点击清空按钮
    emptyClick() {
      this.tags = []
      this.activeName = 'allSort',//一级分类回到“全部分类”上
        /*图片蓝灰切换*/
      this.isAll = true
      this.firstSortList[0].img = require('@/assets/img/edible.png');
      this.firstSortList[1].img = require('@/assets/img/inedible.png');
      this.firstSortList[2].img = require('@/assets/img/medicine.png');
      this.firstSortList[3].img = require('@/assets/img/handicrafts.png');
      this.firstSortList[4].img = require('@/assets/img/other.png');
      this.secondSortList = []
      this.showThird = false
      this.sortIsAll = true //二级分类的全部变蓝
      this.typeActiveIndex = ""//二级分类的其他变灰
      this.subSortIsAll = true //三级分类的全部变蓝
      this.subTypeActiveIndex = "" //三级分类的其他变灰
      this.firstType = ""
      this.secondType = ""
      this.thirdType = ""
      this.getList()
    },

    //查询条件标签关闭
    handleClose(tag) {
      if (tag.code.length == 2) { //关闭一级查询条件
        this.tags.splice(this.tags.indexOf(tag), 3);

        this.activeName = 'allSort',//一级分类回到“全部分类”上
        /*图片蓝灰切换*/
        this.isAll = true
        this.firstSortList[0].img = require('@/assets/img/edible.png');
        this.firstSortList[1].img = require('@/assets/img/inedible.png');
        this.firstSortList[2].img = require('@/assets/img/medicine.png');
        this.firstSortList[3].img = require('@/assets/img/handicrafts.png');
        this.firstSortList[4].img = require('@/assets/img/other.png');
        this.sortIsAll = true //二级分类的全部变蓝
        this.typeActiveIndex = ""//二级分类的其他变灰
        this.subSortIsAll = true //三级分类的全部变蓝
        this.subTypeActiveIndex = "" //三级分类的其他变灰
        //调用查询方法
        this.firstType = ""
        this.secondType = ""
        this.thirdType = ""
        this.getList()
      } else if (tag.code.length == 4) { //关闭二级查询条件
        this.tags.splice(this.tags.indexOf(tag), 2);

        this.sortIsAll = true //二级分类的全部变蓝
        this.typeActiveIndex = ""//二级分类的其他变灰
        this.thirdSortList = [] //三级分类不显示
        this.isThird = false
        this.isSecond = false
        this.getList()

      } else { //关闭三级查询条件
        this.tags.splice(this.tags.indexOf(tag), 1);

        this.thirdSortList = [] //三级分类不显示
        this.isThird = false
        this.isSecond = true
        this.getList()
      }
    },

    //地区查询
    getArea() {
      axios({
        method: "get",
        url: process.env.VUE_APP_HUSSAR_DEFAULT_API + "/api/portal/app/region/show",
      }).then(res => {
        if (res.data.code === 10000 || res.data.code === 200) {
          this.areaList = res.data.data

        } else {
          this.$message.warning("请求失败")
        }
      }).catch(err => {
        this.$message.error('请求失败')
      })
    },

    //一级分类查询
    getSort() {
      let params = {
        productTypetypeCodeEqual: 2
      }
      axios({
        method: "post",
        url: process.env.VUE_APP_HUSSAR_DEFAULT_API + "/giinfo/productType/hussarQueryproductTypeCondition_2",
        data: params
      }).then(res => {
        if (res.data.code === 10000 || res.data.code === 200) {
          this.firstSortList = res.data.data.data
          this.firstSortList[0].img = require('@/assets/img/edible.png');
          this.firstSortList[1].img = require('@/assets/img/inedible.png');
          this.firstSortList[2].img = require('@/assets/img/medicine.png');
          this.firstSortList[3].img = require('@/assets/img/handicrafts.png');
          this.firstSortList[4].img = require('@/assets/img/other.png');
        } else {
          this.$message.warning("请求失败")
        }
      }).catch(err => {
        this.$message.error('请求失败')
      })
    },

    getSortOther() {
      let params = {
        productTypetypeCodeEqual: 2
      }
      axios({
        method: "post",
        url: process.env.VUE_APP_HUSSAR_DEFAULT_API + "/giinfo/productType/hussarQueryproductTypeCondition_2",
        data: params
      }).then(res => {
        if (res.data.code === 10000 || res.data.code === 200) {
          this.firstSortList = res.data.data.data
          this.firstSortList[0].img = require('@/assets/img/edible.png');
          this.firstSortList[1].img = require('@/assets/img/inedible.png');
          this.firstSortList[2].img = require('@/assets/img/medicine.png');
          this.firstSortList[3].img = require('@/assets/img/handicrafts.png');
          this.firstSortList[4].img = require('@/assets/img/other.png');
          this.firstSortClickOther(this.firstTypeItem)
        } else {
          this.$message.warning("请求失败")
        }
      }).catch(err => {
        this.$message.error('请求失败')
      })
    },

    getSortOtherTwo() {
      let params = {
        productTypetypeCodeEqual: 2
      }
      axios({
        method: "post",
        url: process.env.VUE_APP_HUSSAR_DEFAULT_API + "/giinfo/productType/hussarQueryproductTypeCondition_2",
        data: params
      }).then(res => {
        if (res.data.code === 10000 || res.data.code === 200) {
          this.firstSortList = res.data.data.data
          this.firstSortList[0].img = require('@/assets/img/edible.png');
          this.firstSortList[1].img = require('@/assets/img/inedible.png');
          this.firstSortList[2].img = require('@/assets/img/medicine.png');
          this.firstSortList[3].img = require('@/assets/img/handicrafts.png');
          this.firstSortList[4].img = require('@/assets/img/other.png');
          this.firstSortClickOtherTwo(this.firstTypeItem)
        } else {
          this.$message.warning("请求失败")
        }
      }).catch(err => {
        this.$message.error('请求失败')
      })
    },

    getSortOtherThree() {
      let params = {
        productTypetypeCodeEqual: 2
      }
      axios({
        method: "post",
        url: process.env.VUE_APP_HUSSAR_DEFAULT_API + "/giinfo/productType/hussarQueryproductTypeCondition_2",
        data: params
      }).then(res => {
        if (res.data.code === 10000 || res.data.code === 200) {
          this.firstSortList = res.data.data.data
          this.firstSortList[0].img = require('@/assets/img/edible.png');
          this.firstSortList[1].img = require('@/assets/img/inedible.png');
          this.firstSortList[2].img = require('@/assets/img/medicine.png');
          this.firstSortList[3].img = require('@/assets/img/handicrafts.png');
          this.firstSortList[4].img = require('@/assets/img/other.png');
          this.firstSortClickOtherThree(this.firstTypeItem)
        } else {
          this.$message.warning("请求失败")
        }
      }).catch(err => {
        this.$message.error('请求失败')
      })
    },


    //二级分类查询
    getSecondSort() {
      let params = {
        productTypetypeCodeEqual: this.firstSortCode
      }
      axios({
        method: "post",
        url: process.env.VUE_APP_HUSSAR_DEFAULT_API + "/giinfo/productType/hussarQueryproductTypeCondition_1",
        data: params
      }).then(res => {
        if (res.data.code === 10000 || res.data.code === 200) {
          this.secondSortList = res.data.data.data
        } else {
          this.$message.warning("请求失败")
        }
      }).catch(err => {
        this.$message.error('请求失败')
      })
    },

    getSecondSortOther() {
      let params = {
        productTypetypeCodeEqual: this.firstSortCode
      }
      axios({
        method: "post",
        url: process.env.VUE_APP_HUSSAR_DEFAULT_API + "/giinfo/productType/hussarQueryproductTypeCondition_1",
        data: params
      }).then(res => {
        if (res.data.code === 10000 || res.data.code === 200) {
          this.secondSortList = res.data.data.data
          this.clickTypeTabOther(this.secondTypeItem, this.secondIndex)
        } else {
          this.$message.warning("请求失败")
        }
      }).catch(err => {
        this.$message.error('请求失败')
      })
    },

    getSecondSortThree() {
      let params = {
        productTypetypeCodeEqual: this.firstSortCode
      }
      axios({
        method: "post",
        url: process.env.VUE_APP_HUSSAR_DEFAULT_API + "/giinfo/productType/hussarQueryproductTypeCondition_1",
        data: params
      }).then(res => {
        if (res.data.code === 10000 || res.data.code === 200) {
          this.secondSortList = res.data.data.data
          this.clickTypeTabThree(this.secondTypeItem, this.secondIndex)
        } else {
          this.$message.warning("请求失败")
        }
      }).catch(err => {
        this.$message.error('请求失败')
      })
    },

    //三级分类查询
    getThirdSort() {
      let params = {
        productTypetypeCodeEqual: this.secondSortCode
      }
      axios({
        method: "post",
        url: process.env.VUE_APP_HUSSAR_DEFAULT_API + "/giinfo/productType/hussarQueryproductTypeCondition_1",
        data: params
      }).then(res => {
        if (res.data.code === 10000 || res.data.code === 200) {
          this.thirdSortList = res.data.data.data
          if (res.data.data.data.length != 0) {
            this.showThird = true
          }
        } else {
          this.$message.warning("请求失败")
        }
      }).catch(err => {
        this.$message.error('请求失败')
      })
    },

    getThirdSortOther() {
      let params = {
        productTypetypeCodeEqual: this.secondSortCode
      }
      axios({
        method: "post",
        url: process.env.VUE_APP_HUSSAR_DEFAULT_API + "/giinfo/productType/hussarQueryproductTypeCondition_1",
        data: params
      }).then(res => {
        if (res.data.code === 10000 || res.data.code === 200) {
          this.thirdSortList = res.data.data.data
          if (res.data.data.data.length != 0) {
            this.showThird = true
          }
          this.clickSubTypeTabOther(this.thirdTypeItem, this.thirdIndex)
        } else {
          this.$message.warning("请求失败")
        }
      }).catch(err => {
        this.$message.error('请求失败')
      })
    },

    // 条数变化
    handleSizeChange(val) {
      this.pageSize = val
      this.getList();
    },
    pageChange(val) {
      this.page = val
      this.getList();
    },
    // 上一页
    prevClick(val) {
      this.page = val
      this.getList()
    },
    // 下一页
    nextClick(val) {
      this.page = val
      this.getList()
    },

    //查询产品列表
    getList() {
      let params = {
        size: this.pageSize,
        current: this.page,
        //type: this.type,
        county: this.county,
        searchKey: this.keyWord,
        giSign: this.giSign,
      }

      if (this.isThird == true) {
        params.type = this.thirdType
      } else {
        if (this.isSecond == true) {
          params.type = this.secondType
        } else {
          params.type = this.firstType
        }
      }

      this.pageKey++;

      /*查询条件*/
      this.productParamSize = params.size
      this.productParamCurrent = params.current
      this.productParamCounty = params.county
      this.productParamSearchKey = params.searchKey
      this.productParamGiSign = params.giSign
      this.productParamType = params.type
      this.productParamIsThird = this.isThird
      this.productParamIsSecond = this.isSecond
      axios({
        method: "get",
        url: process.env.VUE_APP_HUSSAR_DEFAULT_API + "/api/portal/app/product/show",
        params: params
      }).then(res => {
        if (res.data.code === 10000 || res.data.code === 200) {
          this.dataList = res.data.data.records
          for (let i = 0; i < this.dataList.length; i++) {
            this.dataList[i].img = this.dataList[i].giProductsPic.split(',')[0] //用，分隔取第1个
          }
          this.tableTotal = res.data.data.total


        } else {
          this.$message.warning("请求失败")
        }
      }).catch(err => {
        this.$message.error('请求失败')
      })

    },

    //一级分类点击
    firstSortClick(item) {
      this.firstTypeItem = item;
      this.page = 1
      this.pageSize = 12
      if (item == undefined) {
        this.isAll = true
        this.firstSortList[0].img = require('@/assets/img/edible.png');
        this.firstSortList[1].img = require('@/assets/img/inedible.png');
        this.firstSortList[2].img = require('@/assets/img/medicine.png');
        this.firstSortList[3].img = require('@/assets/img/handicrafts.png');
        this.firstSortList[4].img = require('@/assets/img/other.png');
        this.showThird = false
      } else {
        if (item.typeCode == "01") { //食用农林产品及食品
          this.firstSortList[0].img = require('@/assets/img/edibleBlue.png');
          this.firstSortList[1].img = require('@/assets/img/inedible.png');
          this.firstSortList[2].img = require('@/assets/img/medicine.png');
          this.firstSortList[3].img = require('@/assets/img/handicrafts.png');
          this.firstSortList[4].img = require('@/assets/img/other.png');
          this.isAll = false
          this.showThird = false
        }
        if (item.typeCode == "02") { //非食用农林产品
          this.firstSortList[1].img = require('@/assets/img/inedibleBlue.png');
          this.firstSortList[0].img = require('@/assets/img/edible.png');
          this.firstSortList[2].img = require('@/assets/img/medicine.png');
          this.firstSortList[3].img = require('@/assets/img/handicrafts.png');
          this.firstSortList[4].img = require('@/assets/img/other.png');
          this.isAll = false
          this.showThird = false
        }
        if (item.typeCode == "03") { //中药材
          this.firstSortList[2].img = require('@/assets/img/medicineBlue.png');
          this.firstSortList[0].img = require('@/assets/img/edible.png');
          this.firstSortList[1].img = require('@/assets/img/inedible.png');
          this.firstSortList[3].img = require('@/assets/img/handicrafts.png');
          this.firstSortList[4].img = require('@/assets/img/other.png');
          this.isAll = false
          this.showThird = false
        }
        if (item.typeCode == "04") { //手工艺品
          this.firstSortList[3].img = require('@/assets/img/handicraftsBlue.png');
          this.firstSortList[0].img = require('@/assets/img/edible.png');
          this.firstSortList[1].img = require('@/assets/img/inedible.png');
          this.firstSortList[2].img = require('@/assets/img/medicine.png');
          this.firstSortList[4].img = require('@/assets/img/other.png');
          this.isAll = false
          this.showThird = false
        }
        if (item.typeCode == "05") { //其他
          this.firstSortList[4].img = require('@/assets/img/otherBlue.png');
          this.firstSortList[0].img = require('@/assets/img/edible.png');
          this.firstSortList[1].img = require('@/assets/img/inedible.png');
          this.firstSortList[2].img = require('@/assets/img/medicine.png');
          this.firstSortList[3].img = require('@/assets/img/handicrafts.png');
          this.isAll = false
          this.showThird = false
        }
      }
      this.sortIsAll = true //二级分类的全部变蓝
      this.typeActiveIndex = ""//二级分类的其他变灰
      if (item == undefined) { //点击全部分类
        //调用产品列表查询的接口
        this.firstType = ""
        this.secondType = ""
        this.isSecond = false
        this.isThird = false
        this.getList()
        this.secondSortList = []
        this.thirdSortList = []
        this.tags = []//清空查询条件
      } else { //点击除全部分类外的其他
        //console.log('一级分类item', item);
        this.firstSortCode =  item.typeCode
        //调用查询二级分类的接口
        this.getSecondSort()
        //this.type = item.typeName
        this.firstType = item.typeCode
        this.isSecond = false
        this.isThird = false
        //调用产品列表查询的接口
        this.getList()
        this.thirdSortList = []
        this.tags = []
        //将点击获得的名字放在tags数组中
        let obj = {}
        obj.name = item.typeName
        obj.code = item.typeCode
        this.tags.push(obj)

      }
    },

    firstSortClickOther(item) {
      this.firstTypeItem = item;
      if (item == undefined) {
        this.isAll = true
        this.firstSortList[0].img = require('@/assets/img/edible.png');
        this.firstSortList[1].img = require('@/assets/img/inedible.png');
        this.firstSortList[2].img = require('@/assets/img/medicine.png');
        this.firstSortList[3].img = require('@/assets/img/handicrafts.png');
        this.firstSortList[4].img = require('@/assets/img/other.png');
        this.showThird = false
      } else {
        if (item.typeCode == "01") { //食用农林产品及食品
          this.firstSortList[0].img = require('@/assets/img/edibleBlue.png');
          this.firstSortList[1].img = require('@/assets/img/inedible.png');
          this.firstSortList[2].img = require('@/assets/img/medicine.png');
          this.firstSortList[3].img = require('@/assets/img/handicrafts.png');
          this.firstSortList[4].img = require('@/assets/img/other.png');
          this.isAll = false
          this.showThird = false
        }
        if (item.typeCode == "02") { //非食用农林产品
          this.firstSortList[1].img = require('@/assets/img/inedibleBlue.png');
          this.firstSortList[0].img = require('@/assets/img/edible.png');
          this.firstSortList[2].img = require('@/assets/img/medicine.png');
          this.firstSortList[3].img = require('@/assets/img/handicrafts.png');
          this.firstSortList[4].img = require('@/assets/img/other.png');
          this.isAll = false
          this.showThird = false
        }
        if (item.typeCode == "03") { //中药材
          this.firstSortList[2].img = require('@/assets/img/medicineBlue.png');
          this.firstSortList[0].img = require('@/assets/img/edible.png');
          this.firstSortList[1].img = require('@/assets/img/inedible.png');
          this.firstSortList[3].img = require('@/assets/img/handicrafts.png');
          this.firstSortList[4].img = require('@/assets/img/other.png');
          this.isAll = false
          this.showThird = false
        }
        if (item.typeCode == "04") { //手工艺品
          this.firstSortList[3].img = require('@/assets/img/handicraftsBlue.png');
          this.firstSortList[0].img = require('@/assets/img/edible.png');
          this.firstSortList[1].img = require('@/assets/img/inedible.png');
          this.firstSortList[2].img = require('@/assets/img/medicine.png');
          this.firstSortList[4].img = require('@/assets/img/other.png');
          this.isAll = false
          this.showThird = false
        }
        if (item.typeCode == "05") { //其他
          this.firstSortList[4].img = require('@/assets/img/otherBlue.png');
          this.firstSortList[0].img = require('@/assets/img/edible.png');
          this.firstSortList[1].img = require('@/assets/img/inedible.png');
          this.firstSortList[2].img = require('@/assets/img/medicine.png');
          this.firstSortList[3].img = require('@/assets/img/handicrafts.png');
          this.isAll = false
          this.showThird = false
        }
      }
      this.sortIsAll = true //二级分类的全部变蓝
      this.typeActiveIndex = ""//二级分类的其他变灰
      if (item == undefined) { //点击全部分类
        //调用产品列表查询的接口
        this.firstType = ""
        this.secondType = ""
        this.isSecond = false
        this.isThird = false
        this.getList()
        this.secondSortList = []
        this.thirdSortList = []
        this.tags = []//清空查询条件
      } else { //点击除全部分类外的其他
        this.firstSortCode =  item.typeCode
        //调用查询二级分类的接口
        this.getSecondSort()
        this.firstType = item.typeCode
        this.isSecond = false
        this.isThird = false
        //调用产品列表查询的接口
        this.getList()
        this.thirdSortList = []
      }
    },

    firstSortClickOtherTwo(item) {
      this.firstTypeItem = item;
      this.page = this.productParamCurrent
      this.pageSize = this.productParamSize
      if (item == undefined) {
        this.isAll = true
        this.firstSortList[0].img = require('@/assets/img/edible.png');
        this.firstSortList[1].img = require('@/assets/img/inedible.png');
        this.firstSortList[2].img = require('@/assets/img/medicine.png');
        this.firstSortList[3].img = require('@/assets/img/handicrafts.png');
        this.firstSortList[4].img = require('@/assets/img/other.png');
        this.showThird = false
      } else {
        if (item.typeCode == "01") { //食用农林产品及食品
          this.firstSortList[0].img = require('@/assets/img/edibleBlue.png');
          this.firstSortList[1].img = require('@/assets/img/inedible.png');
          this.firstSortList[2].img = require('@/assets/img/medicine.png');
          this.firstSortList[3].img = require('@/assets/img/handicrafts.png');
          this.firstSortList[4].img = require('@/assets/img/other.png');
          this.isAll = false
          this.showThird = false
        }
        if (item.typeCode == "02") { //非食用农林产品
          this.firstSortList[1].img = require('@/assets/img/inedibleBlue.png');
          this.firstSortList[0].img = require('@/assets/img/edible.png');
          this.firstSortList[2].img = require('@/assets/img/medicine.png');
          this.firstSortList[3].img = require('@/assets/img/handicrafts.png');
          this.firstSortList[4].img = require('@/assets/img/other.png');
          this.isAll = false
          this.showThird = false
        }
        if (item.typeCode == "03") { //中药材
          this.firstSortList[2].img = require('@/assets/img/medicineBlue.png');
          this.firstSortList[0].img = require('@/assets/img/edible.png');
          this.firstSortList[1].img = require('@/assets/img/inedible.png');
          this.firstSortList[3].img = require('@/assets/img/handicrafts.png');
          this.firstSortList[4].img = require('@/assets/img/other.png');
          this.isAll = false
          this.showThird = false
        }
        if (item.typeCode == "04") { //手工艺品
          this.firstSortList[3].img = require('@/assets/img/handicraftsBlue.png');
          this.firstSortList[0].img = require('@/assets/img/edible.png');
          this.firstSortList[1].img = require('@/assets/img/inedible.png');
          this.firstSortList[2].img = require('@/assets/img/medicine.png');
          this.firstSortList[4].img = require('@/assets/img/other.png');
          this.isAll = false
          this.showThird = false
        }
        if (item.typeCode == "05") { //其他
          this.firstSortList[4].img = require('@/assets/img/otherBlue.png');
          this.firstSortList[0].img = require('@/assets/img/edible.png');
          this.firstSortList[1].img = require('@/assets/img/inedible.png');
          this.firstSortList[2].img = require('@/assets/img/medicine.png');
          this.firstSortList[3].img = require('@/assets/img/handicrafts.png');
          this.isAll = false
          this.showThird = false
        }
      }
      this.sortIsAll = true //二级分类的全部变蓝
      this.typeActiveIndex = ""//二级分类的其他变灰
      if (item == undefined) { //点击全部分类
        //调用产品列表查询的接口
        this.firstType = ""
        this.secondType = ""
        this.isSecond = false
        this.isThird = false
        this.secondSortList = []
        this.thirdSortList = []
        this.tags = []//清空查询条件
      } else { //点击除全部分类外的其他
        this.firstSortCode =  item.typeCode
        //调用查询二级分类的接口
        this.getSecondSort()
        this.firstType = item.typeCode
        this.isSecond = false
        this.isThird = false
        this.thirdSortList = []
        this.tags = []
        //将点击获得的名字放在tags数组中
        let obj = {}
        obj.name = item.typeName
        obj.code = item.typeCode
        this.tags.push(obj)
      }

      if (JSON.stringify(this.productParamSecondTypeItem) == "{}") { //二级分类点击的是全部
        this.sortIsAll = true
      } else {
        this.secondTypeItem = this.productParamSecondTypeItem;
        this.secondIndex = Number(this.productParamSecondIndex);
        this.firstSortCode = this.firstTypeItem.typeCode
        let obj2 = {}
        obj2.name = this.secondTypeItem.typeName
        obj2.code = this.secondTypeItem.typeCode
        this.tags.push(obj2)
      }
      this.getSecondSortOther()
    },

    firstSortClickOtherThree(item) {
      this.firstTypeItem = item;
      this.page = this.productParamCurrent
      this.pageSize = this.productParamSize
      if (item == undefined) {
        this.isAll = true
        this.firstSortList[0].img = require('@/assets/img/edible.png');
        this.firstSortList[1].img = require('@/assets/img/inedible.png');
        this.firstSortList[2].img = require('@/assets/img/medicine.png');
        this.firstSortList[3].img = require('@/assets/img/handicrafts.png');
        this.firstSortList[4].img = require('@/assets/img/other.png');
        this.showThird = false
      } else {
        if (item.typeCode == "01") { //食用农林产品及食品
          this.firstSortList[0].img = require('@/assets/img/edibleBlue.png');
          this.firstSortList[1].img = require('@/assets/img/inedible.png');
          this.firstSortList[2].img = require('@/assets/img/medicine.png');
          this.firstSortList[3].img = require('@/assets/img/handicrafts.png');
          this.firstSortList[4].img = require('@/assets/img/other.png');
          this.isAll = false
          this.showThird = false
        }
        if (item.typeCode == "02") { //非食用农林产品
          this.firstSortList[1].img = require('@/assets/img/inedibleBlue.png');
          this.firstSortList[0].img = require('@/assets/img/edible.png');
          this.firstSortList[2].img = require('@/assets/img/medicine.png');
          this.firstSortList[3].img = require('@/assets/img/handicrafts.png');
          this.firstSortList[4].img = require('@/assets/img/other.png');
          this.isAll = false
          this.showThird = false
        }
        if (item.typeCode == "03") { //中药材
          this.firstSortList[2].img = require('@/assets/img/medicineBlue.png');
          this.firstSortList[0].img = require('@/assets/img/edible.png');
          this.firstSortList[1].img = require('@/assets/img/inedible.png');
          this.firstSortList[3].img = require('@/assets/img/handicrafts.png');
          this.firstSortList[4].img = require('@/assets/img/other.png');
          this.isAll = false
          this.showThird = false
        }
        if (item.typeCode == "04") { //手工艺品
          this.firstSortList[3].img = require('@/assets/img/handicraftsBlue.png');
          this.firstSortList[0].img = require('@/assets/img/edible.png');
          this.firstSortList[1].img = require('@/assets/img/inedible.png');
          this.firstSortList[2].img = require('@/assets/img/medicine.png');
          this.firstSortList[4].img = require('@/assets/img/other.png');
          this.isAll = false
          this.showThird = false
        }
        if (item.typeCode == "05") { //其他
          this.firstSortList[4].img = require('@/assets/img/otherBlue.png');
          this.firstSortList[0].img = require('@/assets/img/edible.png');
          this.firstSortList[1].img = require('@/assets/img/inedible.png');
          this.firstSortList[2].img = require('@/assets/img/medicine.png');
          this.firstSortList[3].img = require('@/assets/img/handicrafts.png');
          this.isAll = false
          this.showThird = false
        }
      }
      this.sortIsAll = true //二级分类的全部变蓝
      this.typeActiveIndex = ""//二级分类的其他变灰
      if (item == undefined) { //点击全部分类
        //调用产品列表查询的接口
        this.firstType = ""
        this.secondType = ""
        this.isSecond = false
        this.isThird = false
        this.secondSortList = []
        this.thirdSortList = []
        this.tags = []//清空查询条件
      } else { //点击除全部分类外的其他
        this.firstSortCode =  item.typeCode
        //调用查询二级分类的接口
        this.getSecondSort()
        this.firstType = item.typeCode
        this.isSecond = false
        this.isThird = false
        this.thirdSortList = []
        this.tags = []
        //将点击获得的名字放在tags数组中
        let obj = {}
        obj.name = item.typeName
        obj.code = item.typeCode
        this.tags.push(obj)
      }
      if (JSON.stringify(this.productParamSecondTypeItem) == "{}") { //二级分类点击的是全部
        this.sortIsAll = true
      } else {
        this.secondTypeItem = this.productParamSecondTypeItem;
        this.secondIndex = Number(this.productParamSecondIndex);
        this.firstSortCode = this.firstTypeItem.typeCode
        let obj2 = {}
        obj2.name = this.secondTypeItem.typeName
        obj2.code = this.secondTypeItem.typeCode
        this.tags.push(obj2)
      }
      this.getSecondSortThree()
    },

    //二级分类点击
    clickTypeTab(val, index) {
      this.secondTypeItem = val
      this.secondIndex = index
      this.page = 1
      this.pageSize = 12
      this.subSortIsAll = true //三级分类的全部变蓝
      this.subTypeActiveIndex = "" //三级分类的其他变灰
      if (val == undefined) {
        this.secondType = ""
        this.thirdType = ""
        this.isSecond = false
        this.isThird = false
        this.getList()
        this.thirdSortList = []
        this.sortIsAll = true
        this.typeActiveIndex = ""
        if (this.tags.length >2) {
          this.tags.splice(1,1)
        }
        if (this.tags.length > 1) {
          this.tags.splice(1,1)
        }
      } else {
        //console.log("二级分类点击", val)
        this.typeActiveIndex = index;
        this.sortIsAll = false //全部按钮不显示蓝色
        this.secondSortCode = val.typeCode
        //调用查询三级分类的接口
        this.getThirdSort()
        //this.type = val.typeName
        this.secondType = val.typeCode
        this.thirdType = ""
        this.isSecond = true
        this.isThird = false
        this.getList()
        if (this.tags.length >2) {
          this.tags.splice(1,1)
        }
        if (this.tags.length > 1) {
          this.tags.splice(1,1)
        }
        //将点击获得的名字放在tags数组中
        let obj = {}
        obj.name = val.typeName
        obj.code = val.typeCode
        this.tags.splice(1, 0, obj)

      }
    },

    clickTypeTabOther(val, index) {
      this.secondTypeItem = val
      this.secondIndex = index
      this.page = this.productParamCurrent
      this.pageSize = this.productParamSize
      this.subSortIsAll = true //三级分类的全部变蓝
      this.subTypeActiveIndex = "" //三级分类的其他变灰
      if (val == undefined) {
        this.secondType = ""
        this.thirdType = ""
        this.isSecond = false
        this.isThird = false
        this.getList()
        this.thirdSortList = []
        this.sortIsAll = true
        this.typeActiveIndex = ""
      } else {
        this.typeActiveIndex = index;
        this.sortIsAll = false //全部按钮不显示蓝色
        this.secondSortCode = val.typeCode
        //调用查询三级分类的接口
        this.getThirdSort()
        this.isSecond = true
        this.isThird = false
        this.getList()
      }
    },

    clickTypeTabThree(val, index) {
      this.secondTypeItem = val
      this.secondIndex = index
      this.page = this.productParamCurrent
      this.pageSize = this.productParamSize
      this.subSortIsAll = true //三级分类的全部变蓝
      this.subTypeActiveIndex = "" //三级分类的其他变灰
      if (val == undefined) {
        this.secondType = ""
        this.thirdType = ""
        this.isSecond = false
        this.isThird = false
        this.thirdSortList = []
        this.sortIsAll = true
        this.typeActiveIndex = ""
      } else {
        this.typeActiveIndex = index;
        this.sortIsAll = false //全部按钮不显示蓝色
        this.secondSortCode = val.typeCode
        this.isSecond = true
        this.isThird = false
        //调用查询三级分类的接口
        this.getThirdSortOther()
      }
  },

    //三级分类点击
    clickSubTypeTab(val, index) {
      this.thirdTypeItem = val
      this.thirdIndex = index
      this.page = 1
      this.pageSize = 12
      if (val == undefined) {
        this.isThird = false
        this.isSecond = true
        this.thirdType = ""
        this.getList()
        this.subSortIsAll = true
        this.subTypeActiveIndex = ""
        if (this.tags.length >2) {
          this.tags.splice(2,1)
        }
      } else {
        this.isThird = true
        this.subTypeActiveIndex = index;
        this.subSortIsAll = false
        //console.log("三级分类点击", val)
        //this.type = val.typeName
        this.thirdType = val.typeCode
        //this.firstType = ""
        //this.secondType = ""
        this.getList()

        //将点击获得的名字放在tags数组中
        if (this.tags.length > 2) {
          this.tags.pop()
        }

        //将点击获得的名字放在tags数组中
        let obj = {}
        obj.name = val.typeName
        obj.code = val.typeCode
        this.tags.push(obj)

      }
    },

    clickSubTypeTabOther(val, index) {
      this.thirdTypeItem = val
      this.thirdIndex = index
      this.page = this.productParamCurrent
      this.pageSize = this.productParamSize
      if (val == undefined) {
        this.isThird = false
        this.isSecond = true
        this.thirdType = ""
        this.getList()
        this.subSortIsAll = true
        this.subTypeActiveIndex = ""
        if (this.tags.length >2) {
          this.tags.splice(2,1)
        }
      } else {
        this.isThird = true
        this.subTypeActiveIndex = index;
        this.subSortIsAll = false
        this.thirdType = val.typeCode
        this.getList()
        let obj3 = {}
        obj3.name = val.typeName
        obj3.code = val.typeCode
        this.tags.push(obj3)
      }
    },

    //地区点击
    clickAreaTab(val, index) {
      this.page = 1
      this.pageSize = 12
      if (val == undefined) {
        this.areaActiveIndex = ""
        this.areaIsAll = true
        this.county = ""
        this.getList()
      } else {
        //console.log("地区点击", val)
        this.areaActiveIndex = index
        this.areaIsAll = false //全部按钮不显示蓝色
        this.county = val.regionName
        this.getList()
      }
    },

    goDetail(datas) {
      this.projectId = datas.giProductsId
      this.$router.push({
        path: '/richPeasant/productInfo',
        query: {
          id: this.projectId,
          productParamSize: this.productParamSize,
          productParamCurrent: this.productParamCurrent,
          productParamCounty: this.productParamCounty,
          productParamSearchKey: this.productParamSearchKey,
          productParamGiSign: this.productParamGiSign,
          productParamType: this.productParamType,
          productParamIsThird: this.productParamIsThird,
          productParamIsSecond: this.productParamIsSecond,
          productParamAreaActiveIndex: this.areaActiveIndex,
          productParamLabelActiveIndex: this.labelActiveIndex,
          productParamFirstTypeItem: this.firstTypeItem,
          productParamSecondTypeItem: this.secondTypeItem,
          productParamSecondIndex: this.secondIndex,
          productParamThirdTypeItem: this.thirdTypeItem,
          productParamThirdIndex: this.thirdIndex,
        }
      })
    },
  }
}
</script>

<style scoped lang="less">
@import '~@/assets/css/common.less';
.main-div {
  background-color: #f8f9fa !important;
}
.breadTop {
  width: 1200px;
  margin: auto;
}
.content-div {
  margin: auto;
  padding: 32px 20px 12px 20px;
  width: 1160px;
  background: #FFFFFF;
  .searchDiv {
    display: flex;
    margin-bottom: 10px;
    margin-left: 15%;
    .moreBtn {
      position: relative;
      left: -20%;
      width: 100px;
      height: 52px;
      background: #186CF5;
      border-radius: 48px;
    }
  }
  .firstSort {
    margin: 30px auto;
  }
  .sortDiv {
    display: flex;
    margin: 20px 0px;
    .sortList-left {
      width: 50px;
      white-space: nowrap;//禁止文字换行
      .left-title {
        font-size: 14px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #888888;
        line-height: 21px;
      }
    }
    .sortList-center {
      .sortClass {
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        padding: 0;
        margin: 0;
        .sortAll {
          color: #409eff;
          margin-right: 40px;
          font-size: 14px;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
        }
        .sortNotAll {
          color: #888888;
          margin-right: 40px;
          font-size: 14px;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
        }
      }
    }
  }
  .subSortDiv {
    display: flex;
    margin: 20px 0px;
    .sub-sortList-left {
      width: 50px;
      .sub-left-title {
        font-size: 14px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #888888;
        line-height: 21px;
      }
    }
    .sub-sortList-center {
      .subSortClass {
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        padding: 0;
        margin: 0;
        .subSortIsAll {
          color: #409eff;
          margin-right: 40px;
          font-size: 14px;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
        }
        .subSortNotAll {
          color: #888888;
          margin-right: 40px;
          font-size: 14px;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
        }
      }
    }
  }
  .areaDiv {
    display: flex;
    margin: 20px 0px;
    .areaList-left {
      width: 50px;
      .left-title {
        font-size: 14px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #888888;
        line-height: 21px;
      }
    }
    .areaList-center {
      .areaClass {
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        padding: 0;
        margin: 0;
        .areaAll {
          color: #409eff;
          margin-right: 40px;
          font-size: 14px;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
        }
        .areaNotAll {
          color: #888888;
          margin-right: 40px;
          font-size: 14px;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
        }
      }
    }
  }
  .search-total {
    display: flex;
    justify-content: space-between;
    .searchClass {
      display: flex;

      .emptyClass {
        width: 70px;
        height: 32px;
        background: #F1F2F2;
        border-radius: 5px;
        line-height: 8px;
      }

    }
    .totalDiv {
      display: flex;
      justify-content: flex-end;
      font-size: 14px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #999999;
      line-height: 21px;
      .totalNum {
        color: #186CF5;
      }
    }
  }
  .listContain {
    min-height: 153px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;

    .infoDiv {
      width: 270px;
      margin: 32px 9px;
      background: #FFFFFF;
      border-radius: 5px;
      border: 1px solid #EEF2F9;
      cursor: pointer;
      text-align: center;
    }
    .infoDiv:hover {
      //background-color: #f0f7fd;
      border: 1px solid #dde5ef;
    }
    .infoDiv > img {
      width: 270px;
      height: 176px;
      border-radius: 5px;
    }
    .textDiv {
      margin: 10px;
      .infoTitle {
        text-align: left;
        font-size: 18px;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 500;
        color: #333333;
        line-height: 27px;
        margin: 18px 0px;
      }
      .introduction {
        text-align: left;
        font-size: 14px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #999999;
        line-height: 22px;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
      .address {
        display: flex;
        align-items: center;
        text-align: left;
        font-size: 12px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #7E91BE;
        line-height: 18px;
        margin: 10px 0px;
      }
    }
  }
  .page {
    text-align: center;
  }
}
.active {
  margin-bottom: 10px;
  .querySpanClass {
    margin-right: 43px;
    margin-bottom: 8px;
    font-size: 14px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #409eff;
  }
}
.unActive {
  margin-bottom: 10px;
  .querySpanClass {
    margin-right: 43px;
    margin-bottom: 8px;
    font-size: 14px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #666666;
  }
}

::v-deep .el-input--prefix .el-input__inner {
  width: 784px;
  height: 52px;
  background: #FFFFFF;
  box-shadow: 0px 5px 25px 0px rgba(20,110,233,0.12);
  border-radius: 48px;
  border: 1px solid #E3EBF9;
}
::v-deep .el-tag {
  margin-right: 20px;
  background: rgba(255,255,255,0);
  border-radius: 13px;
  border: 1px solid #186CF5;
}

::v-deep .el-tabs__item {
  padding: 0px 38px;
  font-size: 16px;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  color: #333333;
  line-height: 24px;
}

::v-deep .el-tabs__item.is-active {
  font-size: 16px;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  color: #186CF5;
  line-height: 24px;
}

::v-deep.el-tabs--top .el-tabs__item.is-top:nth-child(2) {
  padding-left: 100px;
}

::v-deep.el-input .el-input__suffix  {
  .el-input__suffix-inner {
    .el-icon-circle-close.el-input__clear {
      margin-right: 200px;
    }
  }
}

.giLabelDiv {
  display: flex;
  margin: 20px 0px;
  .label-list-left {
    width: 80px;
    .label-left-title {
      font-size: 14px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #888888;
      line-height: 21px;
    }
  }
  .label-list-center {
    .labelClass {
      list-style: none;
      display: flex;
      flex-wrap: wrap;
      padding: 0;
      margin: 0;
      .labelAll {
        color: #409eff;
        margin-right: 40px;
        font-size: 14px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
      }
      .labelNotAll {
        color: #888888;
        margin-right: 40px;
        font-size: 14px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
      }
    }
  }
}
</style>
